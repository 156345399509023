import { Formik } from "formik";
import { Heading } from "../../../../atoms/Heading";
import style from "./index.module.scss";
import CustomButton from "../../../../atoms/CustomButton";
import { getData, patchData, postData } from "../../../../../services";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../assets/styles/components/toast";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import TextInput from "../../../../atoms/TextInput";
import Breadcrumb from "../../../../molecules/Breadcrumb";
import QuillEditor from "../../../../atoms/QuillEditor/QuillEditor";
import { DateSelector } from "../../../../atoms/MonthYearSelector";
import MyDropdown from "../../../../atoms/MyDropdown";
import CounterDropDown from "../../../../molecules/CounterDataDropdown";
import moment from "moment";
import MyInput from "../../../../atoms/MyInput";
import * as Yup from "yup";

const BrokerUpdate = ({ type }) => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["t", "un"]);
  const [priorListData, setPriorListData] = useState([]);
  const [priorData, setPriorData] = useState([]);
  const [initialDataByID, setIntitalDataByID] = useState([]);
  const { id } = useParams();

  const initialValues = {
    type: "brokerUpdate",
    client_id: initialDataByID.client_id || "",
    client_name: initialDataByID.client_name || "",
    date: initialDataByID.note_date || "",
    prior: "",
    details: {
      DEALS: initialDataByID.details?.DEALS || "",
      MC: initialDataByID.details?.MC || "",
      ORIGINATION: initialDataByID.details?.ORIGINATION || "",
      IAD: initialDataByID.details?.IAD || "",
    },
  };

  // const validationSchema = Yup.object().shape({
  //   client_name: Yup.string().required("Client Name is required"),
  //   date: Yup.date().required("Date is required"),
  // });

  const handleSubmit = async (status, values) => {
    if (id) {
      try {
        console.log("Status :", status);
        const res = await patchData({
          endpoint: "notes/update",
          params: {
            id: id,
          },
          data: {
            type: values.type,
            client_name: values.client_name,
            note_date: values.date
              ? values.date
              : moment().utc().startOf("day").toISOString(),
            note_taker: atob(cookies.un),
            details: values.details,
            prior_call: values.prior,
            status: status,
          },
          token: cookies.t,
        });
        if (res) {
          toast.success("Notes Updated Successfully", {
            duration: 1000,
            style: confimationStyles,
          });
          navigate("/dashboard/notes");
        }
      } catch (err) {
        console.log("error", err);
      }
    } else {
      try {
        console.log("Status :", status);
        const res = await postData({
          endpoint: "notes/create",
          data: {
            type: values.type,
            client_name: values.client_name,
            note_date: values.date
              ? values.date
              : moment().utc().startOf("day").toISOString(),
            note_taker: atob(cookies.un),
            details: values.details,
            prior_call: values.prior,
            status: status,
          },
          token: cookies.t,
        });
        if (res) {
          toast.success("Notes Added Successfully", {
            duration: 1000,
            style: confimationStyles,
          });
          navigate("/dashboard/notes");
        }
      } catch (err) {
        console.log("error", err);
      }
    }
  };
  const getPriorCall = async () => {
    const res = await getData({
      endpoint: "notes/getPriorCallList",
      params: { note_type: initialValues.type },
      token: cookies.t,
    });
    setPriorListData(res?.data);
  };

  const getPriorDataByID = async (dataId, status) => {
    const res = await getData({
      endpoint: "notes/getNoteById",
      params: { id: dataId },
      token: cookies.t,
    });
    if (status === "initial") {
      setIntitalDataByID(res?.data);
    } else {
      setPriorData(res?.data);
    }
  };

  useEffect(() => {
    getPriorCall();
    if (id) {
      getPriorDataByID(id, "initial");
    }
  }, [id]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        key={Math.random}
        enableReinitialize
        // validationSchema={validationSchema}
        // validateOnBlur
      >
        {({
          isValid,
          dirty,
          setFieldValue,
          setFieldTouched,
          touched,
          errors,
          values,
          handleBlur,
          isSubmitting,
          resetForm,
        }) => (
          <>
            <Breadcrumb
              labels={["Notes", "Add Broker Update"]}
              stepsToRemove={id ? 1 : 0}
            />
            <Heading className="addHeaderStyle">Add Broker Update</Heading>
            <div className="d-flex flex-wrap w-100 gap-3">
              <MyInput
                type="text"
                name="client_name"
                customholder="Reference Name"
                width="23.9%"
              />
              <div style={{ width: "23.9%", position: "relative" }}>
                <DateSelector
                  placeholder="Note Date"
                  onChange={async (option) => {
                    setFieldValue("date", option);
                  }}
                  selectedDate={values.date}
                />
              </div>
              <MyDropdown
                placeholder="Prior Call"
                data={priorListData}
                onChange={(option) => {
                  setFieldValue("prior", option);
                  getPriorDataByID(option);
                }}
                selectedValue={values?.prior}
                width="35.8%"
              />
            </div>
            <div className="mt-2">
              <QuillEditor
                label="Deals"
                placeholderTextcription="Deals"
                onChange={(content) => setFieldValue(`details.DEALS`, content)}
                value={values.details.DEALS}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                placeholderText={"Past, Current and future deals"}
                data={priorData?.details?.DEALS}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div>
              <QuillEditor
                label="Market Color"
                placeholderTextcription="Market Color"
                onChange={(content) => setFieldValue(`details.MC`, content)}
                value={values.details.MC}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                placeholderText={"Any trends, impressions and sentiment"}
                data={priorData?.details?.MC}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div>
              <QuillEditor
                label="Origination"
                placeholderTextcription="Origination"
                onChange={(content) =>
                  setFieldValue(`details.ORIGINATION`, content)
                }
                value={values.details.ORIGINATION}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                placeholderText={
                  "Level, Quality and Strategy changes and rationale"
                }
                data={priorData?.details?.ORIGINATION}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div>
              <QuillEditor
                label="Instructions and Directions"
                placeholderTextcription="Instructions and Directions"
                onChange={(content) => setFieldValue(`details.IAD`, content)}
                value={values.details.IAD}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                placeholderText={"Internal appetite, direction etc..."}
                data={priorData?.details?.IAD}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div className={style.buttonWrap}>
              <div>
                <CustomButton
                  text="Back"
                  size="btn-lg"
                  type="btn-outline-dark"
                  handleClick={() => {
                    navigate(-1);
                  }}
                />
              </div>
              <div className={style.resetAdd}>
                <CustomButton
                  text="Save"
                  type="btn-outline-primary"
                  size="btn-lg"
                  disabled={isSubmitting}
                  handleClick={() => handleSubmit("Draft", values)}
                />
                <CustomButton
                  text={"Save & Share"}
                  type="btn-primary"
                  size="btn-lg"
                  disabled={isSubmitting}
                  handleClick={() => handleSubmit("Active", values)}
                />
              </div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};
export default BrokerUpdate;

const UserIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clip-path="url(#clip0_93_75)">
        <path
          d="M10 9.99995C10.9889 9.99995 11.9556 9.7067 12.7779 9.1573C13.6001 8.60789 14.241 7.827 14.6194 6.91337C14.9978 5.99974 15.0969 4.9944 14.9039 4.0245C14.711 3.05459 14.2348 2.16368 13.5355 1.46441C12.8363 0.765152 11.9454 0.288949 10.9755 0.0960224C10.0055 -0.0969037 9.00021 0.00211302 8.08658 0.380551C7.17295 0.758989 6.39206 1.39985 5.84265 2.2221C5.29324 3.04434 5 4.01104 5 4.99995C5.00132 6.32562 5.52853 7.59663 6.46593 8.53402C7.40332 9.47142 8.67432 9.99862 10 9.99995ZM10 1.66662C10.6593 1.66662 11.3037 1.86211 11.8519 2.22838C12.4001 2.59465 12.8273 3.11525 13.0796 3.72434C13.3319 4.33342 13.3979 5.00365 13.2693 5.65025C13.1407 6.29685 12.8232 6.8908 12.357 7.35697C11.8908 7.82315 11.2969 8.14062 10.6503 8.26923C10.0037 8.39785 9.33348 8.33184 8.72439 8.07955C8.1153 7.82726 7.59471 7.40001 7.22844 6.85185C6.86216 6.30369 6.66667 5.65922 6.66667 4.99995C6.66667 4.11589 7.01786 3.26805 7.64298 2.64293C8.2681 2.0178 9.11595 1.66662 10 1.66662Z"
          fill="#3B424A"
        />
        <path
          d="M9.99999 11.6672C8.01155 11.6694 6.10517 12.4603 4.69913 13.8663C3.29308 15.2724 2.5022 17.1787 2.49999 19.1672C2.49999 19.3882 2.58779 19.6002 2.74407 19.7564C2.90035 19.9127 3.11231 20.0005 3.33333 20.0005C3.55434 20.0005 3.7663 19.9127 3.92258 19.7564C4.07886 19.6002 4.16666 19.3882 4.16666 19.1672C4.16666 17.6201 4.78124 16.1363 5.8752 15.0424C6.96917 13.9484 8.4529 13.3338 9.99999 13.3338C11.5471 13.3338 13.0308 13.9484 14.1248 15.0424C15.2187 16.1363 15.8333 17.6201 15.8333 19.1672C15.8333 19.3882 15.9211 19.6002 16.0774 19.7564C16.2337 19.9127 16.4456 20.0005 16.6667 20.0005C16.8877 20.0005 17.0996 19.9127 17.2559 19.7564C17.4122 19.6002 17.5 19.3882 17.5 19.1672C17.4978 17.1787 16.7069 15.2724 15.3009 13.8663C13.8948 12.4603 11.9884 11.6694 9.99999 11.6672Z"
          fill="#3B424A"
        />
      </g>
      <defs>
        <clipPath id="clip0_93_75">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default UserIcon;

import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import './styles.scss';

const CustomDatagrid = ({
  rows,
  columns,
  CustomNoRowsOverlay,
  getRowId,
  height,
  tHeight = '31.25rem',
  checkRequired = false,
  onCellClick,
  hoverColor,
  setSelectedRowIds = () => {},
}) => {
  const [isRowsVisible, setIsRowsVisible] = useState(false);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    let timeoutId;

    if (rows && rows.length > 0) {
      setIsRowsVisible(true);
      setShowLoader(false);
    } else {
      timeoutId = setTimeout(() => {
        setIsRowsVisible(false);
        setShowLoader(false);
      }, 500);
    }

    return () => clearTimeout(timeoutId);
  }, [rows]);

  useEffect(() => {
    setIsRowsVisible(rows && rows.length > 0);
  }, [rows]);

  const dynamicHeight = isRowsVisible ? 'auto' : tHeight;

  const modifiedColumns = columns?.map((column) => ({
    ...column,
    sortable: false,
  }));

  const handleSelectionModelChange = (newSelectionModel) => {
    setSelectedRowIds(newSelectionModel); // You can remove this or handle as needed
  };

  return (
    <Box
      sx={{
        minHeight: tHeight,
        marginBottom: '1.5rem',
        transition: 'height 0.3s ease-in-out', // Add smooth height transition
        height: dynamicHeight,
        fontFamily: 'Instrument Sans',
      }}
    >
      <div
        className={`${isRowsVisible ? '' : 'table-div'} h-100 mb-3 mt-3`}
        style={{ height: isRowsVisible ? '100%' : '0' }}
      >
        <DataGrid
          rows={rows}
          columns={modifiedColumns}
          getRowId={getRowId}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
          }}
          loading={showLoader}
          onCellClick={onCellClick}
          checkboxSelection={checkRequired}
          onRowSelectionModelChange={(ids) => {
            if (setSelectedRowIds) {
              setSelectedRowIds(ids);
            }
          }}
          getRowHeight={height}
          disableRowSelectionOnClick
          hideFooterPagination={false}
          hideFooter
          disableColumnMenu
          className="custom-scrollbar"
          sx={{
            '&.MuiDataGrid-root': {
              border: 'none',
              marginTop: '0 !important',
              fontFamily: 'Instrument Sans',
            },
            '&.MuiDataGrid-cellContent': {
              fontFamily: 'Instrument Sans !important',
              fontWeight: '500 !important',
            },
            '& .MuiDataGrid-columnHeader': {
              backgroundColor: '#F1F3F5',
              // padding : '0 10px 0 10px',
            },
            '& .MuiDataGrid-cell': {
              fontSize : '14px',
              color: '#1B242C',
            },
            '& .MuiDataGrid-cell:focus-within': {
              outlineWidth: '0px',
            },
            '& .MuiDataGrid-columnHeader:focus-within': {
              outlineWidth: '0px',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: '500 !important',
              color: '#555F6D !important',
              fontFamily: 'Instrument Sans',
              fontSize: '14px;',
            },
            '& .MuiDataGrid-columnHeader:focus': {
              outline: '0',
            },
            '& .MuiDataGrid-row.Mui-selected': {
              backgroundColor: 'white',
            },
            '& .MuiCheckbox-root': {
              '& .MuiSvgIcon-root': {
                fill: '#9EA8B3 !important',
              },
              '&.Mui-checked .MuiSvgIcon-root': {
                fill: '#146EF5 !important', // Change checked checkbox color
              },
            },
            '& .MuiCheckbox-root.Mui-checked:hover': {
              backgroundColor: 'rgba(255, 87, 34, 0.04) !important', // Change hover background color
            },

            '& .MuiDataGrid-row:hover': {
              background: hoverColor,
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
              width: '0.4em !important',
              height: '0.4em !important',
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
              background: '#f1f1f1 !important',
              height: '2px !important',
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
              backgroundColor: 'eee !important',
              height: '2px !important',
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
              background: '#555 !important',
              height: '0.125rem !important',
            },
            '& .MuiDataGrid-cell:focus': {
              outline: '0px !important',
            },
            overflowX: 'hidden',
          }}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          disableColumnFilter
        />
      </div>
    </Box>
  );
};

export default CustomDatagrid;

// import GuestGuard from './GuestGuard';
// import AuthLayout from '../components/layout/AuthLayout';
// import Login from '../components/pages/Auth/Login';
// import Verification from '../components/pages/Auth/verifyOTP';
// import { Navigate, Routes, Route } from 'react-router-dom';
// import { Suspense } from 'react';
// import DashboardLayout from '../components/layout/DashboardLayout';
// import PriceManagement from '../components/pages/dashboard/setting/price';
// import AddPrice from '../components/pages/dashboard/setting/price/addPrice';
// import ForgotPassword from '../components/pages/Auth/forgotPassword';
// import ResetOrCreatePass from '../components/pages/Auth/createOrResetPassword';
// import AuthGuard from './AuthGuard';
// import BrokerManagement from '../components/pages/dashboard/setting/broker';
// import AddBroker from '../components/pages/dashboard/setting/broker/addBroker';
// import SignerManagement from '../components/pages/dashboard/setting/singer';
// import InsuranceManagement from '../components/pages/dashboard/setting/insurance';
// import AddSigner from '../components/pages/dashboard/setting/singer/addSigner';
// import AddInsurance from '../components/pages/dashboard/setting/insurance/addInsurance';
// import CounterParty from '../components/pages/dashboard/counterparty';
// import AddCounterparty from '../components/pages/dashboard/counterparty/add';
// import InvestorManagement from '../components/pages/dashboard/setting/investor';
// import AddInvestor from '../components/pages/dashboard/setting/investor/addInvestor';
// import PaymentManagement from '../components/pages/dashboard/payments';
// import AddPayment from '../components/pages/dashboard/payments/addPayment';
// import FundingManagement from '../components/pages/dashboard/funding';
// import AddFunding from '../components/pages/dashboard/funding/addFunding';
// import RoleMangement from '../components/pages/dashboard/setting/RoleManagement';
// import AddAccess from '../components/pages/dashboard/setting/RoleManagement/Add/index';

// import AddCovenant from '../components/pages/dashboard/setting/covenant/addCovenant';
// import Covenant from '../components/pages/dashboard/setting/covenant';
// import ReportingRequirement from '../components/pages/dashboard/setting/covenant/reportingRequirement';
// import UserManagement from '../components/pages/dashboard/setting/UserManagement';

// const routes = [
//   {
//     path: '/',
//     element: <Navigate to="/auth" />,
//   },
//   {
//     path: 'auth',
//     element: (
//       <GuestGuard>
//         <AuthLayout />
//       </GuestGuard>
//     ),
//     children: [
//       {
//         index: true,
//         element: <Login />,
//       },
//       {
//         path: 'login',
//         element: <Login />,
//       },
//       {
//         path: 'verify',
//         element: <Verification />,
//       },
//       {
//         path: 'forgot-password',
//         element: <ForgotPassword />,
//       },
//       {
//         path: 'reset-password',
//         element: <ResetOrCreatePass type="reset" />,
//       },
//       {
//         path: 'create-password',
//         element: <ResetOrCreatePass type="create" />,
//       },
//     ],
//   },
//   {
//     path: 'dashboard/*',
//     element: (
//       <AuthGuard>
//         <DashboardLayout />
//       </AuthGuard>
//     ),
//   },
// ];
// export const dashboardRoute = (
//   <Suspense>
//     <Routes>
//       <Route element={<></>}></Route>
//       <Route
//         path="counterparty"
//         element={
//           <AuthGuard>
//             <CounterParty />
//           </AuthGuard>
//         }
//       />

//       <Route
//         path="counterparty/add/:id?"
//         element={
//           <AuthGuard>
//             <AddCounterparty />
//           </AuthGuard>
//         }
//       />
//       <Route
//         path="counterparty/add/:id?"
//         element={
//           <AuthGuard>
//             <AddCounterparty />
//           </AuthGuard>
//         }
//       ></Route>
//       <Route
//         path="funding"
//         element={
//           <AuthGuard>
//             <FundingManagement />
//           </AuthGuard>
//         }
//       />
//       <Route
//         path="funding/add"
//         element={
//           <AuthGuard>
//             <AddFunding />
//           </AuthGuard>
//         }
//       />
//       <Route
//         path="setting/price"
//         element={
//           <AuthGuard>
//             <PriceManagement />
//           </AuthGuard>
//         }
//       ></Route>
//       <Route
//         path="setting/price/add"
//         element={
//           <AuthGuard>
//             <AddPrice />
//           </AuthGuard>
//         }
//       ></Route>
//       <Route
//         path="setting/price/edit/:id?"
//         element={
//           <AuthGuard>
//             <AddPrice type="edit" />
//           </AuthGuard>
//         }
//       ></Route>
//       <Route
//         path="setting/covenant"
//         element={
//           <AuthGuard>
//             <Covenant />
//           </AuthGuard>
//         }
//       ></Route>
//       <Route
//         path="setting/covenant/add/"
//         element={
//           <AuthGuard>
//             <AddCovenant type="add" />
//           </AuthGuard>
//         }
//       ></Route>
//       <Route
//         path="setting/covenant/edit/:id?"
//         element={
//           <AuthGuard>
//             <AddCovenant type="edit" />
//           </AuthGuard>
//         }
//       ></Route>
//       <Route
//         path="setting/covenant/reporting-requirement/:id?"
//         element={
//           <AuthGuard>
//             <ReportingRequirement />
//           </AuthGuard>
//         }
//       />
//       <Route
//         path="/broker"
//         element={
//           <AuthGuard>
//             <BrokerManagement />
//           </AuthGuard>
//         }
//       ></Route>
//       <Route
//         path="/broker/add"
//         element={
//           <AuthGuard>
//             <AddBroker type='add'/>
//           </AuthGuard>
//         }
//       ></Route>
//           <Route
//         path="/broker/edit/:id?"
//         element={
//           <AuthGuard>
//             <AddBroker type='edit'/>
//           </AuthGuard>
//         }
//       ></Route>
//       <Route
//         path="/signer"
//         element={
//           <AuthGuard>
//             <SignerManagement />
//           </AuthGuard>
//         }
//       ></Route>
//       <Route
//         path="/signer/add"
//         element={
//           <AuthGuard>
//             <AddSigner type='add'/>
//           </AuthGuard>
//         }
//       ></Route>
//        <Route
//         path="/signer/edit/:id?"
//         element={
//           <AuthGuard>
//             <AddSigner type='edit'/>
//           </AuthGuard>
//         }
//       />
//       <Route
//         path="/insurance"
//         element={
//           <AuthGuard>
//             <InsuranceManagement />
//           </AuthGuard>
//         }
//       ></Route>
//       <Route
//         path="/insurance/add"
//         element={
//           <AuthGuard>
//             <AddInsurance type='add'/>
//           </AuthGuard>
//         }
//       ></Route>
//             <Route
//         path="/insurance/edit/:id?"
//         element={
//           <AuthGuard>
//             <AddInsurance type='edit'/>
//           </AuthGuard>
//         }
//       ></Route>
//       <Route
//         path="/investor"
//         element={
//           <AuthGuard>
//             <InvestorManagement />
//           </AuthGuard>
//         }
//       />
//       <Route
//         path="/investor/add"
//         element={
//           <AuthGuard>
//             <AddInvestor type='add'/>
//           </AuthGuard>
//         }
//       />
//           <Route
//         path="/investor/edit/:id?"
//         element={
//           <AuthGuard>
//             <AddInvestor type='edit'/>
//           </AuthGuard>
//         }
//       />
//       <Route
//         path="/payments"
//         element={
//           <AuthGuard>
//             <PaymentManagement />
//           </AuthGuard>
//         }
//       />
//       <Route
//         path="/payments/add/:id?"
//         element={
//           <AuthGuard>
//             <AddPayment />
//           </AuthGuard>
//         }
//       />
//       <Route
//         path="setting/role-management"
//         element={
//           <AuthGuard>
//             <RoleMangement />
//           </AuthGuard>
//         }
//       />

//       <Route
//         path="setting/role-management/add"
//         element={
//           <AuthGuard>
//             <AddAccess />
//           </AuthGuard>
//         }
//       />

//       <Route
//         path="setting/role-management/edit/:id"
//         element={
//           <AuthGuard>
//             <AddAccess type="edit" />
//           </AuthGuard>
//         }
//       />

//       <Route
//         path="setting/user-management"
//         element={
//           <AuthGuard>
//             <UserManagement />
//           </AuthGuard>
//         }
//       />
//     </Routes>
//   </Suspense>
// );
// export default routes;

import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import GuestGuard from "./GuestGuard";
import AuthLayout from "../components/layout/AuthLayout";
import Login from "../components/pages/Auth/Login";
import Verification from "../components/pages/Auth/verifyOTP";
import ForgotPassword from "../components/pages/Auth/forgotPassword";
import ResetOrCreatePass from "../components/pages/Auth/createOrResetPassword";
import AuthGuard from "./AuthGuard";
import DashboardLayout from "../components/layout/DashboardLayout";
import PriceManagement from "../components/pages/dashboard/setting/price";
import AddPrice from "../components/pages/dashboard/setting/price/addPrice";
import BrokerManagement from "../components/pages/dashboard/setting/broker";
import AddBroker from "../components/pages/dashboard/setting/broker/addBroker";
import SignerManagement from "../components/pages/dashboard/setting/singer";
import InsuranceManagement from "../components/pages/dashboard/setting/insurance";
import AddSigner from "../components/pages/dashboard/setting/singer/addSigner";
import AddInsurance from "../components/pages/dashboard/setting/insurance/addInsurance";
import CounterParty from "../components/pages/dashboard/counterparty";
import AddCounterparty from "../components/pages/dashboard/counterparty/add";
import InvestorManagement from "../components/pages/dashboard/setting/investor";
import AddInvestor from "../components/pages/dashboard/setting/investor/addInvestor";
import PaymentManagement from "../components/pages/dashboard/payments";
import AddPayment from "../components/pages/dashboard/payments/addPayment";
import FundingManagement from "../components/pages/dashboard/funding";
import AddFunding from "../components/pages/dashboard/funding/addFunding";
import RoleMangement from "../components/pages/dashboard/setting/RoleManagement";
import AddAccess from "../components/pages/dashboard/setting/RoleManagement/Add/index";
import AddCovenant from "../components/pages/dashboard/setting/covenant/addCovenant";
import Covenant from "../components/pages/dashboard/setting/covenant";

import Reporting from "../components/pages/dashboard/setting/reportingRequirement";
import AddReporting from "../components/pages/dashboard/setting/reportingRequirement/add";

import ReportingRequirement from "../components/pages/dashboard/setting/covenant/reportingRequirement";
import UserManagement from "../components/pages/dashboard/setting/UserManagement";
import Notes from "../components/pages/dashboard/notes/index";
import Tools from "../components/pages/dashboard/Tools";
import ClientQA from "../components/pages/dashboard/notes/Forms/ClientQA";
import ClientInternalMonitoring from "../components/pages/dashboard/notes/Forms/ClientInternalMonitoring";
import ProspectiveClientQA from "../components/pages/dashboard/notes/Forms/ProspectiveClientQA";
import ProspectiveClientOnboarding from "../components/pages/dashboard/notes/Forms/ProspectiveClientOnboarding";
import ClientMonitoring from "../components/pages/dashboard/notes/Forms/ClientMonitoring";
import PlatformUpdate from "../components/pages/dashboard/notes/Forms/PlatformUpdate";
import BrokerUpdate from "../components/pages/dashboard/notes/Forms/BrokerUpdate";
import ViewCounterparty from "../components/pages/dashboard/counterparty/view";
import ViewDraftFunding from "../components/pages/dashboard/funding/viewFunding/viewDraftFunding";
import EditProfile from "../components/pages/dashboard/edit-profile";
import ChangePassword from "../components/pages/dashboard/edit-profile/change-password";
import Documents from "../components/pages/dashboard/Tools/Documents";
import RateManagement from "../components/pages/dashboard/setting/RateManagement";
import AddRate from "../components/pages/dashboard/setting/RateManagement/AddRate";
import General from "../components/pages/dashboard/notes/Forms/general";
import ToolsView from "../components/pages/dashboard/Tools/ToolsView";
import FundedView from "../components/pages/dashboard/funding/Funded";
import ReviewView from "../components/pages/dashboard/payments/View/ReviewView";

// Define your routes here
const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/auth" />,
  },
  {
    path: "auth",
    element: (
      <GuestGuard>
        <AuthLayout />
      </GuestGuard>
    ),
    children: [
      { index: true, element: <Login /> },
      { path: "login", element: <Login /> },
      { path: "verify", element: <Verification /> },
      { path: "forgot-password", element: <ForgotPassword /> },
      { path: "reset-password", element: <ResetOrCreatePass type="reset" /> },
      { path: "create-password", element: <ResetOrCreatePass type="create" /> },
    ],
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "counterparty",
        element: (
          <AuthGuard>
            <CounterParty />{" "}
          </AuthGuard>
        ),
      },
      { path: "counterparty/add/:id?/:isDraft?", element: <AddCounterparty /> },
      { path: "counterparty/view/:id?", element: <ViewCounterparty /> },
      { path: "funding", element: <FundingManagement /> },
      { path: "funding/add", element: <AddFunding /> },
      { path: "funding/draft/view/:id?", element: <ViewDraftFunding /> },
      { path: "funding/view/:id?", element: <FundedView /> },
      { path: "setting/price", element: <PriceManagement /> },
      { path: "setting/price/add", element: <AddPrice /> },
      { path: "setting/price/edit/:id?", element: <AddPrice type="edit" /> },
      { path: "setting/covenant", element: <Covenant /> },
      { path: "setting/covenant/add", element: <AddCovenant type="add" /> },
      {
        path: "setting/covenant/edit/:id?",
        element: <AddCovenant type="edit" />,
      },
      {
        path: "setting/covenant/reporting-requirement/:id?",
        element: <ReportingRequirement />,
      },
      { path: "setting/reporting-requirements", element: <Reporting /> },
      {
        path: "setting/reporting-requirements/add",
        element: <AddReporting type="add" />,
      },
      {
        path: "setting/reporting-requirements/edit/:id?",
        element: <AddReporting type="edit" />,
      },
      {
        path: "setting/reporting-requirements/reporting-requirement/:id?",
        element: <ReportingRequirement />,
      },
      { path: "broker", element: <BrokerManagement /> },
      { path: "broker/add", element: <AddBroker type="add" /> },
      { path: "broker/edit/:id?", element: <AddBroker type="edit" /> },
      { path: "signer", element: <SignerManagement /> },
      { path: "signer/add", element: <AddSigner type="add" /> },
      { path: "signer/edit/:id?", element: <AddSigner type="edit" /> },
      { path: "insurance", element: <InsuranceManagement /> },
      { path: "insurance/add", element: <AddInsurance type="add" /> },
      { path: "insurance/edit/:id?", element: <AddInsurance type="edit" /> },
      { path: "investor", element: <InvestorManagement /> },
      { path: "investor/add", element: <AddInvestor type="add" /> },
      { path: "investor/edit/:id?", element: <AddInvestor type="edit" /> },
      { path: "payments", element: <PaymentManagement /> },
      { path: "payments/review/view/:id?", element: <ReviewView /> },
      { path: "payments/add/:id?", element: <AddPayment /> },
      { path: "setting/role-management", element: <RoleMangement /> },
      { path: "setting/role-management/add", element: <AddAccess /> },
      {
        path: "setting/role-management/edit/:id",
        element: <AddAccess type="edit" />,
      },
      { path: "setting/user-management", element: <UserManagement /> },
      { path: "setting/rate-management", element: <RateManagement /> },
      { path: "setting/rate-management/add", element: <AddRate /> },
      {
        path: "setting/rate-management/edit/:id?/:date?",
        element: <AddRate type="edit" />,
      },
      { path: "notes", element: <Notes /> },
      { path: "notes/clientQ&A/:id?", element: <ClientQA /> },
      {
        path: "notes/clientInternalMonitoring/:id?",
        element: <ClientInternalMonitoring />,
      },
      { path: "notes/prospectiveQ&A/:id?", element: <ProspectiveClientQA /> },
      {
        path: "notes/prospectiveOnboarding/:id?",
        element: <ProspectiveClientOnboarding />,
      },
      { path: "notes/clientMonitoring/:id?", element: <ClientMonitoring /> },
      { path: "notes/platformUpdate/:id?", element: <PlatformUpdate /> },
      { path: "notes/brokerUpdate/:id?", element: <BrokerUpdate /> },
      { path: "notes/general/:id?", element: <General /> },
      { path: "tools", element: <Tools /> },
      { path: "tools/view/:id?", element: <ToolsView /> },
      { path: "tools/documents", element: <Documents /> },
      { path: "edit-profile", element: <EditProfile /> },
      { path: "change-password", element: <ChangePassword /> },
    ],
  },
]);

export default router;

import PropTypes from "prop-types"; // Fix the import
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles
import "./quillEditor.scss";
import { Heading } from "../Heading";

const QuillEditor = ({
  placeholderText = "Type Something...",
  label,
  height = "180px",
  hp = "70%",
  value,
  onChange,
  classes,
  onBlur,
  type = "",
  description,
  data = "",
  dataHeading = "",
}) => {
  const [editorValue, setEditorValue] = useState(value || "");

  useEffect(() => {
    setEditorValue(value);
  }, [value]);

  const handleEditorChange = (value) => {
    setEditorValue(value);
    onChange(value);
  };

  const defaultToolbarOptions = {
    container: [
      [{ header: [1, 2, 3, 4, false] }],
      ["bold", "italic", "underline"], // toggled buttons              // custom button values
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ], // remove formatting button
    ],
  };

  const customToolbarOptions = {
    ...defaultToolbarOptions,
    container: [
      ...defaultToolbarOptions.container,
      ["link", "image"], // Add 'link' and 'image' options here
    ],
  };

  const modules = {
    toolbar: type === "faq" ? customToolbarOptions : defaultToolbarOptions,
  };

  return (
    <>
      <div className="d-flex flex-column">
        <label className="label-quill">{label}</label>
        {description && (
          <label className="label-description">{description}</label>
        )}
      </div>
      <div className="d-flex justify-content-between">
        <div
          style={{
            height: data && data.length > 500 ? "530px" : "285px",
            width: data ? "49%" : "100%",
          }}
          className={classes}
        >
          <ReactQuill
            style={{
              borderRadius: "4px",
              height: data && data.length > 500 ? "87%" : "80%",
            }}
            theme="snow" // You can choose a different theme if needed
            value={editorValue}
            onChange={handleEditorChange}
            placeholder={placeholderText}
            contentType="html"
            onBlur={onBlur}
            modules={modules}
          />
          {/* Additional UI components or buttons can be added as needed */}
        </div>
        {data && (
          <div
            style={{
              width: "49%",
            }}
          >
            <Heading className="h5 mb-3 mt-0">{dataHeading}</Heading>
            <div
              className="prior-viewer"
              style={{
                height: data?.length > 500 ? "460px" : height,
                width: "100%",
              }}
              dangerouslySetInnerHTML={{ __html: data }}
            ></div>
          </div>
        )}
      </div>
    </>
  );
};

QuillEditor.propTypes = {
  placeholderText: PropTypes.string,
  label: PropTypes.string,
};

export default QuillEditor;

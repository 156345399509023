import { Formik } from "formik";
import { Heading } from "../../../../atoms/Heading";
import style from "./index.module.scss";
import CustomButton from "../../../../atoms/CustomButton";
import { getData, patchData, postData } from "../../../../../services";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../assets/styles/components/toast";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import TextInput from "../../../../atoms/TextInput";
import Breadcrumb from "../../../../molecules/Breadcrumb";
import QuillEditor from "../../../../atoms/QuillEditor/QuillEditor";
import { DateSelector } from "../../../../atoms/MonthYearSelector";
import MyDropdown from "../../../../atoms/MyDropdown";
import CounterDropDown from "../../../../molecules/CounterDataDropdown";
import moment from "moment";
import MyInput from "../../../../atoms/MyInput";

const ProspectiveClientOnboarding = ({ type }) => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["t", "un"]);
  const [priorListData, setPriorListData] = useState([]);
  const [priorData, setPriorData] = useState([]);
  const [initialDataByID, setIntitalDataByID] = useState([]);
  const { id } = useParams();

  const initialValues = {
    type: "prospectiveOnboarding",
    client_id: initialDataByID.client_id || "",
    client_name: initialDataByID.client_name || "",
    date: initialDataByID.note_date || "",
    prior: "",
    details: {
      CO: initialDataByID.details?.CO || "",
      CMT: initialDataByID.details?.CMT || "",
      CBV: initialDataByID.details?.CBV || "",
      CSOD: initialDataByID.details?.CSOD || "",
      CFR: initialDataByID.details?.CFR || "",
      FIS: initialDataByID.details?.FIS || "",
      FBS: initialDataByID.details?.FBS || "",
      FWC: initialDataByID.details?.FWC || "",
      OS: initialDataByID.details?.OS || "",
      OI: initialDataByID.details?.OI || "",
      OB: initialDataByID.details?.OB || "",
    },
  };

  const handleSubmit = async (status, values) => {
    if (id) {
      try {
        console.log("Status :", status);
        const res = await patchData({
          endpoint: "notes/update",
          params: {
            id: id,
          },
          data: {
            type: values.type,
            client_name: values.client_name,
            note_date: values.date
              ? values.date
              : moment().utc().startOf("day").toISOString(),
            note_taker: atob(cookies.un),
            details: values.details,
            prior_call: values.prior,
            status: status,
          },
          token: cookies.t,
        });
        if (res) {
          toast.success("Notes Updated Successfully", {
            duration: 1000,
            style: confimationStyles,
          });
          navigate("/dashboard/notes");
        }
      } catch (err) {
        console.log("error", err);
      }
    } else {
      try {
        console.log("Status :", status);
        const res = await postData({
          endpoint: "notes/create",
          data: {
            type: values.type,
            client_name: values.client_name,
            note_date: values.date
              ? values.date
              : moment().utc().startOf("day").toISOString(),
            note_taker: atob(cookies.un),
            details: values.details,
            prior_call: values.prior,
            status: status,
          },
          token: cookies.t,
        });
        if (res) {
          toast.success("Notes Added Successfully", {
            duration: 1000,
            style: confimationStyles,
          });
          navigate("/dashboard/notes");
        }
      } catch (err) {
        console.log("error", err);
      }
    }
  };
  const getPriorCall = async () => {
    const res = await getData({
      endpoint: "notes/getPriorCallList",
      params: { note_type: initialValues.type },
      token: cookies.t,
    });
    setPriorListData(res?.data);
  };

  const getPriorDataByID = async (dataId, status) => {
    const res = await getData({
      endpoint: "notes/getNoteById",
      params: { id: dataId },
      token: cookies.t,
    });
    if (status === "initial") {
      setIntitalDataByID(res?.data);
    } else {
      setPriorData(res?.data);
    }
  };

  useEffect(() => {
    getPriorCall();
    if (id) {
      getPriorDataByID(id, "initial");
    }
  }, [id]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        key={Math.random}
        enableReinitialize
      >
        {({
          isValid,
          dirty,
          setFieldValue,
          setFieldTouched,
          touched,
          errors,
          values,
          handleBlur,
          resetForm,
          isSubmitting,
        }) => (
          <>
            <Breadcrumb
              labels={["Notes", "Add Prospective Client Onboarding"]}
              stepsToRemove={id ? 1 : 0}
            />
            <Heading className="addHeaderStyle">
              Add Prospective Client Onboarding
            </Heading>
            <div className="d-flex flex-wrap w-100 gap-3">
              <MyInput
                type="text"
                name="client_name"
                customholder="Reference Name"
                width="23.9%"
              />
              <div style={{ width: "23.9%", position: "relative" }}>
                <DateSelector
                  placeholder="Note Date"
                  onChange={async (option) => {
                    setFieldValue("date", option);
                  }}
                  selectedDate={values.date}
                />
              </div>
              <MyDropdown
                placeholder="Prior Call"
                data={priorListData}
                onChange={(option) => {
                  setFieldValue("prior", option);
                  getPriorDataByID(option);
                }}
                selectedValue={values?.prior}
                width="35.8%"
              />
            </div>
            <div className="mt-2">
              <QuillEditor
                label="Corporate Overview"
                placeholderTextcription="Corporate Overview"
                onChange={(content) => setFieldValue(`details.CO`, content)}
                value={values.details.CO}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                placeholderText={"General overview"}
                data={priorData?.details?.CO}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div>
              <QuillEditor
                label="Corporate Management Team"
                placeholderTextcription="Corporate Management Team"
                onChange={(content) => setFieldValue(`details.CMT`, content)}
                value={values.details.CMT}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                placeholderText={"Background, overall and relevant experience"}
                data={priorData?.details?.CMT}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div>
              <QuillEditor
                label="Corporate Business Model and Value Drivers"
                placeholderText="Corporate Business Model and Value Drivers"
                onChange={(content) => setFieldValue(`details.CBV`, content)}
                value={values.details.CBV}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                data={priorData?.details?.CBV}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div>
              <QuillEditor
                label="Corporate Structure, Ownership and Domicile"
                placeholderText="Corporate Structure, Ownership and Domicile"
                onChange={(content) => setFieldValue(`details.CSOD`, content)}
                value={values.details.CSOD}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                data={priorData?.details?.CSOD}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div>
              <QuillEditor
                label="Corporate Functional Roles"
                placeholderTextcription="Corporate Functional Roles"
                onChange={(content) => setFieldValue(`details.CFR`, content)}
                value={values.details.CFR}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                placeholderText={
                  "Reasons for multiple entities, and setup e.g. tax"
                }
                data={priorData?.details?.CFR}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div>
              <QuillEditor
                label="Financial Income Statement"
                placeholderTextcription="Financial Income Statement"
                onChange={(content) => setFieldValue(`details.FIS`, content)}
                value={values.details.FIS}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                placeholderText={
                  "Revenue trend and outlook, Gross margins, Net Income"
                }
                data={priorData?.details?.FIS}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div>
              <QuillEditor
                label="Financial Balance Sheet"
                placeholderTextcription="Financial Balance Sheet"
                onChange={(content) => setFieldValue(`details.FBS`, content)}
                value={values.details.FBS}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                placeholderText={
                  "Business funding (inception, historically, current), liabilities, equity"
                }
                data={priorData?.details?.FBS}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div>
              <QuillEditor
                label="Financial Working Capital"
                placeholderTextcription="Financial Working Capital"
                onChange={(content) => setFieldValue(`details.FWC`, content)}
                value={values.details.FWC}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                placeholderText={
                  "What is the constrain currently and what type of funding is required?"
                }
                data={priorData?.details?.FWC}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div>
              <QuillEditor
                label="Operational - Suppliers Issues"
                placeholderTextcription="Operational Suppliers"
                onChange={(content) => setFieldValue(`details.OS`, content)}
                value={values.details.OS}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                placeholderText={
                  "Number of suppliers/key suppliers/location, length of relationship with suppliers/critical suppliers, terms, disputes and resolution, logistics/storage/additional work by company on the product such as assembly/manufacture and associated timing"
                }
                data={priorData?.details?.OS}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div>
              <QuillEditor
                label="Operational Inventory"
                placeholderTextcription="Operational Inventory"
                onChange={(content) => setFieldValue(`details.OI`, content)}
                value={values.details.OI}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                placeholderText={
                  "profile of the inventory(Finished, semi-finished, Obsolete, perishable) approach to inventory PO driven vs general stock"
                }
                data={priorData?.details?.OI}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div>
              <QuillEditor
                label="Operational Buyers"
                placeholderTextcription="Operational Buyers"
                onChange={(content) => setFieldValue(`details.OB`, content)}
                value={values.details.OB}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                placeholderText={
                  "Number of clients, Concentration, length of relationship with key buyers, credit terms, policy and monitoring, collections disputes and dilution"
                }
                data={priorData?.details?.OB}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div className={style.buttonWrap}>
              <div>
                <CustomButton
                  text="Back"
                  size="btn-lg"
                  type="btn-outline-dark"
                  handleClick={() => {
                    navigate(-1);
                  }}
                />
              </div>
              <div className={style.resetAdd}>
                <CustomButton
                  text="Save"
                  type="btn-outline-primary"
                  size="btn-lg"
                  disabled={isSubmitting}
                  handleClick={() => handleSubmit("Draft", values)}
                />
                <CustomButton
                  text={"Save & Share"}
                  type="btn-primary"
                  size="btn-lg"
                  disabled={isSubmitting}
                  handleClick={() => handleSubmit("Active", values)}
                />
              </div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};
export default ProspectiveClientOnboarding;

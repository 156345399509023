import React, { useEffect, useState } from "react";
import { Field, Formik } from "formik";
import Breadcrumb from "../../../../../molecules/Breadcrumb";
import { Heading } from "../../../../../atoms/Heading";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import MyDropdown from "../../../../../atoms/MyDropdown";
import TextInput from "../../../../../atoms/TextInput";
import MyTextArea from "../../../../../atoms/MyTextArea";
import CustomButton from "../../../../../atoms/CustomButton";
import * as Yup from "yup";
import CounterDropDown from "../../../../../molecules/CounterDataDropdown";
import { convenantFrequency } from "../../../../../../helpers/staticData";
import { postData, patchData, getData } from "../../../../../../services";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../../assets/styles/components/toast";
import {
  monthly_shift,
  reportingDays,
} from "../../../../../../helpers/staticData";

const AddCovenant = ({ type }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [cookies] = useCookies(["t"]);
  const [initialData, setInitialData] = useState({});

  useEffect(() => {
    if (type === "edit") {
      (async () => {
        const res = await getData({
          endpoint: "/covenant/getCovenantById",
          params: { id: id },
          token: cookies.t,
        });
        setInitialData(res.data);
      })();
    }
  }, [type]);

  const initialValues = {
    client_id: initialData?.client_id || "",
    client_name: initialData?.client_name || "",
    name: initialData?.name || "",
    long_description: initialData?.long_description || "",
    frequency: initialData?.frequency || "",
    other_notes: initialData?.other_notes || "",
    reporting_days: initialData?.reporting_days || "",
    monthly_shift: initialData?.monthly_shift || "",
  };

  const validationSchema = Yup.object().shape({
    client_id: Yup.string().required("Client Name is required"),
    name: Yup.string().required("Covenant name is required"),
    long_description: Yup.string().required("Description is required"),
    frequency: Yup.string().required("Frequency is required"),
    reporting_days: Yup.string().required("Reporting Days are required"),
    monthly_shift: Yup.string().required("Monthly Shift is required"),
  });

  const handleSubmit = async (values) => {
    if (id) {
      const res = await patchData({
        endpoint: "covenant/updateCovenant",
        token: cookies.t,
        data: values,
        params: {
          id: id,
        },
      });
      if (res) {
        toast.success("Covenant Details Updated Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        navigate("/dashboard/setting/covenant");
      }
    } else {
      try {
        const res = await postData({
          endpoint: "covenant/create",
          data: values,
          token: cookies.t,
        });
        if (res) {
          toast.success("Covenant Details Added Successfully", {
            duration: 1000,
            style: confimationStyles,
          });
          navigate("/dashboard/setting/covenant");
        }
      } catch (err) {
        console.log("error", err);
      }
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        key={Math.random}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          isValid,
          dirty,
          setFieldValue,
          touched,
          errors,
          values,
          handleBlur,
          handleSubmit,
          setFieldTouched,
          resetForm,
          isSubmitting,
        }) => (
          <>
            <Breadcrumb
              labels={
                type === "edit"
                  ? ["Covenant", "Edit Covenant"]
                  : ["Covenant", "Add Covenant"]
              }
              excludeLastPath={type === "edit"}
            />
            <Heading className="addHeaderStyle">
              {id ? "Edit" : "Add"} Covenant
            </Heading>

            <div className="row g-3 mt-3">
              <div className="col-3 mt-3">
                <CounterDropDown
                  placeholder="Client Name"
                  onChange={(option) => {
                    setFieldValue("client_name", option.label);
                    setFieldValue("client_id", option?.value);
                  }}
                  selectedValue={values?.client_id}
                  format="all"
                  isError={errors.client_id}
                  touched={touched.client_id}
                  handleBlur={(e) => {
                    handleBlur(e);
                    setFieldTouched("client_id", true);
                  }}
                  errors={errors.client_id}
                />
              </div>

              <div className="col-3 mt-3">
                <TextInput
                  type="text"
                  name="name"
                  customholder="Name"
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                  InputHeight="small"
                  info="Short Description of Covenant"
                />
              </div>
              <div className="col-6 mt-3">
                <MyTextArea
                  as="textarea"
                  customholder="Description"
                  name="long_description"
                  width="w-100"
                  style={{ minHeight: "46px" }}
                />
              </div>

              <div className="col-3 mt-3">
                <MyDropdown
                  placeholder="Frequency"
                  data={convenantFrequency}
                  onChange={(option) => {
                    setFieldValue("frequency", option.label);
                  }}
                  selectedValue={values?.frequency}
                  format="all"
                  isError={errors.frequency}
                  touched={touched.frequency}
                  handleBlur={(e) => {
                    handleBlur(e);
                    setFieldTouched("frequency", true);
                  }}
                  errors={errors.frequency}
                />
              </div>
              <div className="col-3 mt-3">
                <MyDropdown
                  placeholder="Monthly Shift"
                  data={monthly_shift}
                  onChange={(option) => {
                    setFieldValue("monthly_shift", option);
                  }}
                  selectedValue={values?.monthly_shift}
                  isError={errors.monthly_shift}
                  touched={touched.monthly_shift}
                  handleBlur={(e) => {
                    handleBlur(e);
                    setFieldTouched("monthly_shift", true);
                  }}
                  errors={errors.monthly_shift}
                  info="For quarterly max shift is 2 for annual Max shift is 11"
                />
              </div>
              <div className="col-3 mt-3">
                <MyDropdown
                  placeholder="Reporting Days"
                  data={reportingDays}
                  onChange={(option) => {
                    setFieldValue("reporting_days", option);
                  }}
                  selectedValue={values?.reporting_days}
                  isError={errors.reporting_days}
                  touched={touched.reporting_days}
                  handleBlur={(e) => {
                    handleBlur(e);
                    setFieldTouched("reporting_days", true);
                  }}
                  errors={errors.reporting_days}
                  info="If reporting days are N/A. Please select 0."
                />
              </div>
              <div className="col-3 mt-3">
                <MyTextArea
                  as="textarea"
                  customholder="Other Notes"
                  name="other_notes"
                  width="w-100"
                />
              </div>
            </div>

            <div className="buttonWrap mt-5">
              <div>
                <CustomButton
                  text="Back"
                  size="btn-lg"
                  type="btn-outline-dark"
                  handleClick={() => {
                    navigate(-1);
                  }}
                />
              </div>
              <div className="resetAdd">
                <CustomButton
                  text="Reset"
                  type="btn-outline-primary"
                  size="btn-lg"
                  handleClick={() => resetForm()}
                />
                <button
                  className="btn-primary btn-lg"
                  type="submit"
                  disabled={!isValid || !dirty || isSubmitting}
                  style={{ width: "100%" }}
                  onClick={() => handleSubmit(values)}
                >
                  {id ? "Update" : "Add"}
                  {isSubmitting && (
                    <span
                      className="spinner-border spinner-border-sm ms-2 text-light"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
              </div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

export default AddCovenant;
